import { SetterOrUpdater, useRecoilCallback, useRecoilValue, useSetRecoilState } from "recoil";

import { useAffiliateLead } from "../../../core/application/affiliate-lead-use-cases";
import { trackingParamsState } from "../../../core/state/analytics-state";
import { toCamelCase } from "../../../core/utils/objects";
import { useCountry } from "../../../func-core/core/application/hooks/useCountry";
import { CountryCode } from "../../../settings/countries";
import { getAllUtmsPrefixed } from "../../../utils/utm";
import { Lead, LeadDTO, LeadValuesUpdatable } from "../domain/lead";
import { leadState } from "../state/lead-state";

export function useLead(): Lead {
  const lead: LeadDTO = useRecoilValue(leadState);
  const { country } = useCountry();

  const petBirthDate = lead.petBirthDate && new Date(lead.petBirthDate);
  const countryIso = country.iso as CountryCode;
  const newLead = { ...lead, countryIso, petBirthDate };

  return new Lead(newLead);
}

export function useSetLead(): (values: LeadValuesUpdatable) => Promise<Lead | undefined> {
  const leadSetter: SetterOrUpdater<LeadDTO> = useSetRecoilState(leadState);
  const affiliate_lead = useAffiliateLead();
  const { country } = useCountry();

  return useRecoilCallback(
    ({ snapshot }) =>
      async (values: LeadValuesUpdatable): Promise<Lead | undefined> => {
        const trackingParams = await snapshot.getPromise(trackingParamsState);
        const currentLead: LeadDTO = await snapshot.getPromise(leadState);
        const countryIso = country.iso as CountryCode;
        const utms = toCamelCase(getAllUtmsPrefixed());

        const newLead: LeadDTO = {
          ...currentLead,
          ...values,
          affiliate_lead,
          ...trackingParams,
          countryIso,
          ...utms,
        };

        leadSetter(newLead);

        return new Lead(newLead);
      },
    [leadSetter]
  );
}
